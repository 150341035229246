import React from 'react'
import { ModalWrapper, Reoverlay } from 'reoverlay'
import InnerHTML from 'dangerously-set-html-content'

import 'reoverlay/lib/ModalWrapper.css'

//* ==========
//* Partner Slate Form
const x =
  '<div id="form_f5dfc318-53a1-4d37-970b-ed4acb62acac">Loading...</div><script>var script = document.createElement("script"); script.async = 1; script.src = "https://admission.du.edu/register/?id=f5dfc318-53a1-4d37-970b-ed4acb62acac&output=embed&div=form_f5dfc318-53a1-4d37-970b-ed4acb62acac" + ((location.search.length > 1) ? "&" + location.search.substring(1) : ""); var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(script, s);</script>'

//* ==========
//* EAB Slate Form
// const x = `<div id="form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1">Loading...</div><script>/*<![CDATA[*/var script = document.createElement('script'); script.async = 1; script.src = 'https://apply.collegeofeab.org/register/?id=ed7c8a3f-7cfd-4458-b109-3a210dd58df1&output=embed&div=form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1' + ((location.search.length > 1) ? '&' + location.search.substring(1) : ''); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`

const InquireModal = ({ program, onConfirm }) => {
  const closeModal = () => {
    Reoverlay.hideModal()
  }

  return (
    <ModalWrapper
      contentContainerClassName="modal-content"
      wrapperClassName="modal__wrapper"
      onClose={() => Reoverlay.hideModal()}
      animation="slideUp"
    >
      <div className="inquiry-modal">
        {/* Program: {program} */}
        <InnerHTML html={x} />
        <button className="close-modal" type="button" onClick={closeModal}>
          Close modal
        </button>
      </div>
    </ModalWrapper>
  )
}
export default InquireModal
