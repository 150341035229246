import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const CardContainer = styled.div`
  padding: 1rem;
  /* background: ${({ theme }) => (theme === 'light' ? '#fff' : '#181717')}; */
  height: 100%;
`

const StyledDescription = styled.div`
  /* font-size: 1.5rem; */
  color: #999;
`

const Title = ({ data }) => {
  if (!data) return null
  return <div className="cardTitle" dangerouslySetInnerHTML={{ __html: data }} />
}
const Description = ({ data }) => {
  if (!data) return null
  return <StyledDescription className="cardDescription" dangerouslySetInnerHTML={{ __html: data }} />
}

const Card = ({ title, description, image }) => (
  <CardContainer className="card-container">
    <Title data={title} />
    <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />
    <Description data={description} />
  </CardContainer>
)

export default Card
