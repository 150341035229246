import Container from 'components/common/Container'
import React from 'react'
import getLandingPageModules from 'utils/getLandingPageModules'

function Section({ data, index }) {
  return (
    <Container className={`section ${data.cssClass}`}>
      <div className="container">
        {data.sections?.map((module, i) => module?.internal && getLandingPageModules(module, `${index}.${i}`))}
      </div>
    </Container>
  )
}

export default Section
