import React from 'react'
import CTA from 'components/content-modules/CTA'
import Headline from 'components/content-modules/Headline'
import HeroImage from 'components/content-modules/HeroImage'
import Container from 'components/common/Container'
import Head from 'components/theme/Head'

function Header({ data }) {
  // console.log('🚀 data', data)
  const { image, cta, headline, subHeadline } = data

  return (
    <>
      <Head />
      <Container className="heroSection">
        <div className="container">
          <HeroImage data={image} />
          <div className="hero-content">
            <Headline data={headline} />
            <p className="subHeadline">{subHeadline}</p>
            <CTA data={cta} />
          </div>
        </div>
      </Container>
    </>
  )
}

export default Header
