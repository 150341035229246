import React from 'react'
import { graphql } from 'gatsby'
import { ModalContainer } from 'reoverlay'
import { SEO } from 'components/common'
import getLandingPageModules from 'utils/getLandingPageModules'
import { Footer } from 'components/theme'

import { CampaignContext } from '../store'

const PageTemplate = ({ data }) => {
  const { slug, sections, pageTitle } = data.contentfulPageLandingPage
  return (
    <>
      <SEO title={pageTitle} />
      <CampaignContext.Provider value={slug}>
        <main className={slug}>
          {sections?.map((module, i) => module?.internal && getLandingPageModules(module, i))}
        </main>
        <Footer />
        <ModalContainer />
      </CampaignContext.Provider>
    </>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    contentfulPageLandingPage(id: { eq: $id }) {
      title
      slug
      pageTitle
      sections {
        ...AllComponentTypes
        ...SectionsRecursiveFragment
      }
    }
  }
`
